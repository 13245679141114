import { useContext, useEffect } from "react";
import { StoreContext } from "../store/Store";
import { createReport } from "./utils";
import { GlobalRefContext } from "../store/GlobalRefs";
import { getGroups } from "../api/clio";
import { actionTypes } from "../store/storeTypes";
import { RunOptions } from "./RunOptions";
import { SelectAtttorneys } from "./SelectAttorneys";
import { SelectGroups } from "./SelectGroups";
import { getGrouping } from "./grouping";

const buttonStyle = {
  display: "inline-block",
  backgroundColor: "cyan",
  color: "#000000",
  padding: 8,
  border: "1px solid black",
  borderRadius: 8,
  cursor: "pointer",
  marginTop: 20,
};
export const Download = () => {
  const [store, dispatch] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const {
    auth,
    clioBills,
    clioMatters,
    groups,
    persistedKeyedData,
    clioActivitiesKeyed,
    clioActivitiesAttorneyMatters,
    runAttorneys,
    runGroups,
    runOption,
  } = store;

  useEffect(() => {
    //get groups
    if (!groups) {
      const asyncfunction = async () => {
        const ret = await getGroups(auth);
        const groups = JSON.parse(ret.body);
        dispatch({ type: actionTypes.SET_GROUPS, payload: groups });
      };
      asyncfunction();
    }
  }, [auth, dispatch, groups]);

  const onClick = () => {
    const filteredClioMatters = clioMatters.filter(
      (item) =>
        item.originating_attorney?.name &&
        item.responsible_attorney?.name &&
        item.originating_attorney.name.trim() !== "" &&
        item.responsible_attorney.name.trim() !== ""
    );
    const groupedData = getGrouping(
      groups,
      runOption,
      filteredClioMatters,
      persistedKeyedData,
      clioActivitiesKeyed,
      clioActivitiesAttorneyMatters
    );

    const originating = clioMatters.map(
      (item) => item.originating_attorney.name
    );
    const responsible = clioMatters.map(
      (item) => item.responsible_attorney.name
    );

    const attorneys = []
      .concat(originating)
      .concat(responsible)
      .filter((item) => item !== "");
    const distinctAttorney = Array.from(new Set(attorneys));

    //get attorneys not in a group
    let assignedAttorneys = [];
    groups.forEach((group) => {
      assignedAttorneys = assignedAttorneys.concat(group.attorneys);
    });

    const nonGroupedAttorneys = distinctAttorney.filter((item) => {
      return !assignedAttorneys.includes(item);
    });

    const reportArray =
      runOption === "Run for all groups and unassigned attorneys"
        ? groups
            .map((group) => {
              return group.id;
            })
            .concat(nonGroupedAttorneys)
        : runOption === "Select attorneys"
        ? runAttorneys
        : runOption === "Select groups"
        ? runGroups
        : distinctAttorney;
    console.log("Report Array:", reportArray);
    console.log("Cio matters:", groupedData.clioMatters);

    reportArray.forEach((attorney) => {
      createReport(
        attorney,
        clioBills,
        groupedData.clioMatters,
        groupedData.persistedKeyedData,
        groupedData.clioActivitiesKeyed,
        groupedData.clioActivitiesAttorneyMatters,
        grefs
      );
    });
  };

  return clioBills &&
    clioMatters &&
    persistedKeyedData &&
    clioActivitiesKeyed &&
    clioActivitiesAttorneyMatters &&
    groups ? (
    <div>
      <RunOptions />
      {runOption === "Select attorneys" && <SelectAtttorneys />}
      {runOption === "Select groups" && <SelectGroups />}
      <div style={buttonStyle} onClick={() => onClick()}>
        Download reports
      </div>
    </div>
  ) : null;
};

import React, { useState, useRef } from "react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

export const ExcelUpload = ({ onClose }) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null); // Ref to control the file input

  // Configure AWS S3 client
  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  });

  // Handle file selection
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
      "text/csv", // .csv
    ];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setUploadStatus("");
    } else {
      setFile(null);
      setUploadStatus("Please select a valid Excel or CSV file");
    }
  };

  // Upload file to S3
  const handleUpload = async () => {
    if (!file) {
      setUploadStatus("Please select a file first");
      return;
    }

    try {
      setIsUploading(true);
      setUploadStatus("Uploading...");

      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const fileName = `uploads/${timestamp}-${file.name}`;
      const arrayBuffer = await file.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);

      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: fileName,
        Body: buffer,
        ContentType: file.type,
      };

      const command = new PutObjectCommand(params);
      await s3Client.send(command);

      setUploadStatus("File uploaded successfully!");
      setFile(null); // Clear the file state
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input field
      }
    } catch (error) {
      console.error("Upload error:", error);
      setUploadStatus("Upload failed. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
    maxWidth: "400px",
    width: "90%",
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  };

  const buttonStyle = {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "8px",
    border: "1px solid black",
    borderRadius: "8px",
    cursor: "pointer",
    margin: "0 5px",
  };

  const closeButtonStyle = {
    backgroundColor: "#FF6347",
    color: "white",
    padding: "8px",
    border: "1px solid black",
    borderRadius: "8px",
    cursor: "pointer",
    margin: "0 5px",
  };

  return (
    <>
      <div style={overlayStyle} onClick={onClose}></div>
      <div style={modalStyle}>
        <input
          type="file"
          accept=".xlsx,.xls,.csv"
          onChange={handleFileChange}
          ref={fileInputRef} // Attach ref to the input
          style={{ marginBottom: "10px", display: "block" }}
        />
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <button
            style={buttonStyle}
            onClick={handleUpload}
            disabled={!file || isUploading}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </button>
          <button style={closeButtonStyle} onClick={onClose}>
            Close
          </button>
        </div>
        {uploadStatus && (
          <div
            style={{
              color: uploadStatus.includes("successfully") ? "green" : "red",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            {uploadStatus}
          </div>
        )}
      </div>
    </>
  );
};

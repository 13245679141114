import { useContext, useRef } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";

export const ExpenseTable = ({ attorney }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { expensesFiltered } = store;

  const dateRef = useRef();
  const attorneyDirectExpensesRef = useRef();  
  const totalGeneralExpensesRef = useRef();

  

  const thStyle = { padding: 10 };

  const clickRow = (item) => {
    dispatch({ type: actionTypes.SET_EXPENSE_ROW, payload: item });
  };

  const numberStyle = { textAlign: "right" };
  const rowStyle = { cursor: "pointer" };

  return attorney ? (
    <table>
      <thead>
        <tr>
          <th style={thStyle} ref={dateRef}>
            Date
          </th>
          <th style={thStyle} ref={attorneyDirectExpensesRef}>
            Attorney Direct Expenses
          </th>
          {/* <th style={thStyle} ref={attorneyOriginationRef}>
            Attorney Origination
          </th>
          <th style={thStyle} ref={firmRevenueRef}>
            Firm Revenue
          </th> */}
          <th style={thStyle} ref={totalGeneralExpensesRef}>
            Total General Expenses
          </th>
        </tr>
      </thead>
      <tbody>
        {expensesFiltered
          .sort((a, b) => {
            const adate = new Date(a.date);
            const bdate = new Date(b.date);
            return adate > bdate ? 1 : bdate > adate ? -1 : 0;
          })
          .map((item, i) => {
            return (
              <tr key={i} onClick={() => clickRow(item)} style={rowStyle}>
                <td>{item.date}</td>
                <td style={numberStyle}>
                  {(+item.AttorneyDirectExpenses).toFixed(2)}
                </td>
                {/* <td style={numberStyle}>
                  {(+item.AttorneyOrigination).toFixed(2)}
                </td>
                <td style={numberStyle}>{(+item.FirmRevenue).toFixed(2)}</td> */}
                <td style={numberStyle}>
                  {(+item.TotalGeneralExpenses).toFixed(2)}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  ) : null;
};

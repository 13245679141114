import React, { useContext, useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";

export const SelectAtttorneys = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { clioMatters, persistedData, runAttorneys } = store;
  const [attorneys, setAttorneys] = useState();

  const selectStyle = { width: 600, marginTop: 10 };

  useEffect(() => {
    if (clioMatters && persistedData["expenses"]) {
      const originating = clioMatters.map(
        (item) => item.originating_attorney.name
      );
      const responsible = clioMatters.map(
        (item) => item.responsible_attorney.name
      );

      const expenses = persistedData["expenses"].map(
        (item) => item["attorney"]
      );
      const newattorneys = []
        .concat(originating)
        .concat(responsible)
        .concat(expenses)
        .filter((item) => item !== "");
      const distinctAttorney = Array.from(new Set(newattorneys));

      const attorneyList = distinctAttorney.map((atty, i) => {
        return {
          label: atty,
          value: atty,
        };
      });
      console.log("attorney list:", newattorneys);
      setAttorneys(attorneyList);
    }
  }, [clioMatters, persistedData]);

  return attorneys ? (
    <div style={selectStyle}>
      <Select
        options={attorneys}
        placeholder="Select attorneys"
        values={attorneys.filter((item) => {
          return runAttorneys.includes(item.value);
        })}
        multi={true}
        onChange={(values) =>
          dispatch({
            type: actionTypes.SET_RUN_ATTORNEYS,
            payload: values.map((item) => item.value),
          })
        }
      />
    </div>
  ) : null;
};

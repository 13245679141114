import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { StoreContext } from "../store/Store";
import Auth from "../services/auth";
import { Reports } from "./Reports";
import { ClioData } from "../pages/ClioData";
import { Groups } from "../pages/Groups";
import { actionTypes } from "../store/storeTypes";
import { ExcelUpload } from "./data/UploadExcel";
import { Expenses } from "../pages/Expenses";
import { askClio, getToken, refreshToken } from "../api/clio";
import { TabButtons } from "../components/TabButtons";
import { NewAuth } from "./NewAuth";
import { GetCredentials } from "./GetCredentials";
import { NewPassword } from "./NewPassword";
import { ForgotPassword } from "./ForgotPassword";

const authServ = new Auth();

export const Invoices = () => {
  const [store, dispatch] = useContext(StoreContext) || [{}];
  const [queryParameters] = useSearchParams();
  const {
    code,
    selected,
    auth,
    credentials,
    forgotPassword,
    loggedIn,
    requireNewPassword,
  } = store || {};

  useEffect(() => {
    if (authServ && dispatch) {
      dispatch({ type: actionTypes.SET_AUTH, payload: authServ });
    }
  }, [dispatch]);

  useEffect(() => {
    if (!credentials?.user || !credentials?.password || !auth?.login) {
      return; // Prevent execution if required data is missing
    }

    const asyncfunction = async () => {
      try {
        const res = await auth.login(credentials.user, credentials.password);

        dispatch &&
          dispatch({
            type: actionTypes.SET_LOGGED_IN,
            payload: {
              status: res.status === "success",
              message: res.status !== "success" ? res.errorMessage : "",
            },
          });

        if (res.status === "new-password-required") {
          dispatch &&
            dispatch({
              type: actionTypes.SET_REQUIRE_NEW_PASSWORD,
              payload: true,
            });
          console.log("new password required");
        }
      } catch (error) {
        console.error("Error during login:", error); // Log the error for debugging
      }
    };

    asyncfunction();
  }, [auth, dispatch, credentials]);

  useEffect(() => {
    if (auth) {
      const asyncfunction = async () => {
        const ret = await refreshToken(auth);
        const clioResponse = JSON.parse(ret.body || "{}");
        if (clioResponse.status_code !== 200) {
          alert(clioResponse.response?.error);
        } else {
          dispatch &&
            dispatch({
              type: actionTypes.SET_ACCESS_TOKEN,
              payload: clioResponse.response.access_token,
            });
          dispatch &&
            dispatch({
              type: actionTypes.SET_REFRESH_TOKEN,
              payload: clioResponse.response.refresh_token,
            });
        }
      };
      asyncfunction();
    }
  }, [auth, dispatch]);

  useEffect(() => {
    const clioState = queryParameters.get("state");
    const clioCode = queryParameters.get("code");
    if (dispatch) {
      if (clioState === "retrieved" && !code) {
        dispatch({ type: actionTypes.SET_CODE, payload: clioCode });
      } else if (selected === "New Authorization" && !code) {
        askClio();
      }
    }
  }, [dispatch, queryParameters, code, selected]);

  useEffect(() => {
    if (code && auth) {
      const asyncfunction = async () => {
        const ret = await getToken(auth, code);
        const clioResponse = JSON.parse(ret.body || "{}");
        if (clioResponse.status_code !== 200) {
          alert(clioResponse.response?.error);
        } else {
          dispatch &&
            dispatch({
              type: actionTypes.SET_ACCESS_TOKEN,
              payload: clioResponse.response.access_token,
            });
          dispatch &&
            dispatch({
              type: actionTypes.SET_REFRESH_TOKEN,
              payload: clioResponse.response.refresh_token,
            });
          dispatch &&
            dispatch({
              type: actionTypes.SET_SELECTED,
              payload: "New Authorization",
            });
        }
      };
      asyncfunction();
    }
  }, [dispatch, code, auth]);

  return loggedIn?.status && auth ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TabButtons />
      <div
        style={{
          width: "100%",
          flexGrow: 1,
          flexStretch: 0,
          flexBasis: "auto",
        }}
      >
        {selected === "Reports" && <Reports />}
        {selected === "Expenses" && <Expenses />}
        {selected === "New Authorization" && <NewAuth />}
        {selected === "Data" && <ClioData />}
        {selected === "Groups" && <Groups />}
        {selected === "Upload File" && <ExcelUpload />}
      </div>
    </div>
  ) : (
    <>
      {requireNewPassword ? (
        <NewPassword />
      ) : forgotPassword?.status ? (
        <ForgotPassword />
      ) : (
        <GetCredentials />
      )}
    </>
  );
};

import { applyRange } from "./utils";
import { expenseformulas } from "./formulas";

const formatter = new Intl.DateTimeFormat("en", {
  month: "long",
  year: "numeric",
});

export const expenses = (expensesSheet, attorney, persistedData, grefs) => {
  const a1 = expensesSheet.getCell("A1");
  const b1 = expensesSheet.getCell("B1");
  a1.value = "Attorney:";
  b1.value = attorney;

  //HEADERS
  const b6 = expensesSheet.getCell("B6");
  b6.value = "Revenue % Formula";
  const c6 = expensesSheet.getCell("C6");
  c6.value = "Attorney Direct Expenses";
  const d6 = expensesSheet.getCell("D6");
  d6.value = "Total General Expenses";
  const e6 = expensesSheet.getCell("E6");
  e6.value = "Total Attorney Expense Allocation";
  const f6 = expensesSheet.getCell("F6");
  f6.value = "Distribtion Adjustment";

  expensesSheet.columns = [
    { key: "blank", width: 10 },
    { key: "revform", width: 21.8 },
    { key: "Attorney Direct Expenses", width: 14 },
    { key: "Total General Expenses", width: 14 },
    { key: "Total Attorney Expense Allocation", width: 14 },
    { key: "Distribtion Adjustment", width: 14 },
  ];

  const calculatedCELGValues = grefs.calculatedCELGValues.current[attorney];

  if (!calculatedCELGValues) {
    calculateExpenses(persistedData, attorney, grefs);
  }
  const calculatedValues = grefs.calculatedExpenseValues.current;

  let startRow = 7;
  let row = startRow;
  if (attorney in persistedData.expenses) {
    Object.values(persistedData.expenses[attorney])
      .sort((a, b) => {
        const ad = new Date(a.date);
        const ab = new Date(b.date);
        return ad > ab ? 1 : ab > ad ? -1 : 0;
      })
      .forEach((expense) => {
        expense.date = formatter.format(new Date(expense.date));
        const b = expensesSheet.getCell(`B${row}`);
        const c = expensesSheet.getCell(`C${row}`);
        const d = expensesSheet.getCell(`D${row}`);
        const e = expensesSheet.getCell(`E${row}`);
        const f = expensesSheet.getCell(`F${row}`);
        //VALUES
        b.value = expense.date;
        c.value = +expense.AttorneyDirectExpenses.replace(",", ".");
        d.value = +expense.TotalGeneralExpenses.replace(",", ".");

        //FORMAT
        applyRange(
          expensesSheet,
          `C${row}:F${row}`,
          "numFmt",
          '_("$"* # ##0,00_);_("$"* (# ##0,00);_("$"* "-"??_);_(@_)'
        );
        //BORDER
        b.border = {
          left: { style: "double", color: { argb: "FF000000" } },
        };
        f.border = {
          right: { style: "double", color: { argb: "FF000000" } },
        };
        //ALIGNMENT
        b.alignment = {
          vertical: "middle",
          horizontal: "left",
        };
        //FONT
        b.font = {
          name: "Calibri",
          size: 12,
          bold: "true",
        };
        //FILL
        applyRange(expensesSheet, `E${row}:F${row}`, "fill", {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F8CBAD" },
        });

        //FORMULAS

        e.value = {
          formula: `D${row}+C${row}`,
          result: calculatedValues["E"][row],
        };
        f.value = {
          formula: `-E${row}`,
          result: calculatedValues["F"][row],
        };

        row++;
      });

    row = row - 1;
  }

  //ALIGNENT
  applyRange(expensesSheet, "B6:F6", "alignment", {
    vertical: "bottom",
    horizontal: "center",
    wrapText: true,
  });
  //FILL
  applyRange(expensesSheet, "B6:F6", "fill", {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  });
  //FONT
  applyRange(expensesSheet, "A1:B1", "font", {
    name: "Calibri",
    size: 12,
    bold: "true",
  });
  applyRange(expensesSheet, "B6:F6", "font", {
    name: "Calibri",
    size: 12,
    bold: "true",
  });
  //BORDER

  applyRange(expensesSheet, `C${row}:E${row}`, "border", {
    bottom: { style: "double", color: { argb: "FF000000" } },
  });
  expensesSheet.getCell(`B${row}`).border = {
    left: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  expensesSheet.getCell(`F${row}`).border = {
    right: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };

  applyRange(expensesSheet, "C6:E6", "border", {
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "thick", color: { argb: "FF000000" } },
  });
  expensesSheet.getCell(`B6`).border = {
    top: { style: "double", color: { argb: "FF000000" } },
    left: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "thick", color: { argb: "FF000000" } },
  };
  expensesSheet.getCell(`F6`).border = {
    top: { style: "double", color: { argb: "FF000000" } },
    right: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "thick", color: { argb: "FF000000" } },
  };
};

export const calculateExpenses = (persistedData, attorney, grefs) => {
  let startRow = 7;
  let row = startRow;
  if (attorney in persistedData.expenses) {
    Object.values(persistedData.expenses[attorney])
      .sort((a, b) => {
        const ad = new Date(a.date);
        const ab = new Date(b.date);
        return ad > ab ? 1 : ab > ad ? -1 : 0;
      })
      .forEach((expense) => {
        expense.date = formatter.format(new Date(expense.date));
        expenseformulas(row, "E", expense, grefs, attorney);
        expenseformulas(row, "F", expense, grefs, attorney);
        row++;
      });
  }
};

import { useContext, useState } from "react";
import { StoreContext } from "../../store/Store";
import { Result } from "../utils";
import { ExcelUpload } from "../upload/ExcelUpload";

const endpoints = [
  "allData",
  "bills",
  "matters",
  "persisted",
  "activities",
  "uploadData",
];

const display = {
  bills: "Get Billing Data",
  matters: "Get Matters Data",
  persisted: "Get Persisted Data",
  allData: "Get All the Data",
  activities: "Get Activities Data",
  uploadData: "Upload Data",
};

const buttonStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  backgroundColor: "#B0D9CD",
  color: "#000000",
  padding: 8,
  border: "3px solid black",
  borderRadius: 8,
  cursor: "pointer",
  gap: 10,
};

const buttonStyleMinor = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  backgroundColor: "#F9C48C",
  color: "#000000",
  padding: 8,
  border: "1px solid black",
  borderRadius: 8,
  cursor: "pointer",
  gap: 10,
  fontWeight: "normal",
  fontSize: 12,
};

export const ButtonsToRetrieveData = ({ onClick, errorMessage }) => {
  const [store] = useContext(StoreContext);
  const { status } = store;
  const [showUpload, setShowUpload] = useState(false);

  const handleClick = (item) => {
    if (item === "uploadData") {
      setShowUpload(true);
    } else {
      onClick(item);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <div style={{ display: "flex", gap: 10 }}>
        {endpoints.map((item, i) => (
          <div
            key={i}
            style={i === 0 ? buttonStyle : buttonStyleMinor}
            onClick={() => handleClick(item)}
          >
            {display[item]}
            {item !== "uploadData" && <Result status={status[item]} />}
          </div>
        ))}
      </div>
      {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
      {showUpload && <ExcelUpload onClose={() => setShowUpload(false)} />}
    </div>
  );
};

export let getGrouping = (
  groups,
  runOption,
  clioMatters,
  persistedKeyedData,
  clioActivitiesKeyed,
  clioActivitiesAttorneyMatters
) => {
  const groupsreturned = {};

  console.log("__clio matters", clioMatters);

  const kgroup = {};
  groups.forEach((group) => {
    group.attorneys.forEach((attorney) => {
      kgroup[attorney] = group.id;
    });
  });

  const groupMapping = (attorney) => {
    return runOption === "Run for all groups and unassigned attorneys" ||
      runOption === "Select groups"
      ? attorney in kgroup
        ? kgroup[attorney]
        : attorney
      : attorney;
  };

  //map to groups
  groupsreturned.clioMatters = clioMatters.map((item) => {
    return {
      ...item,
      originating_attorney: {
        ...item.originating_attorney,
        name: groupMapping(item.originating_attorney.name),
      },
      responsible_attorney: {
        ...item.responsible_attorney,
        name: groupMapping(item.responsible_attorney.name),
      },
    };
  });
  groupsreturned.persistedKeyedData = {
    ...persistedKeyedData,
    expenses: Object.entries(persistedKeyedData.expenses).reduce((a, b) => {
      return {
        ...a,
        [groupMapping(b[0])]: Object.entries(b[1]).reduce((c, d) => {
          return {
            ...c,
            [d[0]]: { ...d[1], attorney: groupMapping(d[1].attorney) },
          };
        }, {}),
      };
    }, {}),
  };

  groupsreturned.clioActivitiesKeyed = Object.entries(
    clioActivitiesKeyed
  ).reduce((a, b) => {
    return {
      ...a,
      [b[0]]: Object.entries(b[1]).reduce((c, d) => {
        return {
          ...c,
          [groupMapping(d[0])]:
            groupMapping(d[0]) in c ? c[groupMapping(d[0])] + d[1] : d[1],
        };
      }, {}),
    };
  }, {});

  const groupedClioActivitiesAttorneyMatters = {};
  Object.entries(clioActivitiesAttorneyMatters).forEach((item) => {
    const attny = groupMapping(item[0]);
    groupedClioActivitiesAttorneyMatters[attny] =
      attny in groupedClioActivitiesAttorneyMatters
        ? (groupedClioActivitiesAttorneyMatters[attny] = [
            ...groupedClioActivitiesAttorneyMatters[attny],
            item[1],
          ])
        : (groupedClioActivitiesAttorneyMatters[attny] = item[1]);
  });

  groupsreturned.clioActivitiesAttorneyMatters =
    groupedClioActivitiesAttorneyMatters;

  return groupsreturned;
};

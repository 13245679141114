import { applyRange } from "./utils";

export const matters = (
  mattersSheet,
  billingData,
  attorney,
  mattersData,
  persistedData,
  clioActivitiesAttorneyMatters
) => {
  const billingMatters = billingData.map(
    (item) => item.matters[0].display_number
  );

  const invoicedMatters =
    attorney in clioActivitiesAttorneyMatters
      ? Array.from(clioActivitiesAttorneyMatters[attorney] || [])
      : [];

  const filteredMatters = mattersData.filter((item) => {
    return (
      (item.responsible_attorney.name === attorney ||
        item.originating_attorney.name === attorney ||
        invoicedMatters.includes(item.display_number)) &&
      billingMatters.includes(item.display_number)
    );
  });

  const a1 = mattersSheet.getCell("A1");
  const b1 = mattersSheet.getCell("B1");
  a1.value = "Attorney:";
  b1.value = attorney;

  mattersSheet.mergeCells("B6", "D6");
  const b6 = mattersSheet.getCell("B6");
  b6.value = "Matter Origination, Responsible Attorney or Custom Settings";
  b6.alignment = {
    vertical: "middle",
    horizontal: "left",
  };
  mattersSheet.mergeCells("F6", "I6");
  const f6 = mattersSheet.getCell("F6");
  f6.value = "Custom Percentages (if applicable)";
  f6.alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  mattersSheet.getRow(7).values = [
    "",
    "Matter Name",
    "Originating  Atty",
    "Responsible  Atty",
    "",
    "Guaranteed",
    "On-Collection",
    "Responsible",
    "Originating",
  ];
  mattersSheet.columns = [
    { key: "blank1", width: 10 },
    {
      key: "matterName",
      width: 45.7,
    },
    {
      key: "originatingAtty",
      width: 12.3,
    },
    {
      key: "responsibleAtty",
      width: 12.3,
    },
    { key: "blank2", width: 2.2 },
    { key: "guaranteed", width: 12.4 },
    { key: "onCollection", width: 12.6 },
    { key: "responsible", width: 12.4 },
    { key: "originating", width: 12.4 },
  ];

  let startRow = 8;
  let row = startRow;
  filteredMatters
    .sort((a, b) => {
      return a.display_number > b.display_number
        ? 1
        : b.display_number > a.display_number
        ? -1
        : 0;
    })
    .forEach((matter) => {
      const b = mattersSheet.getCell(`B${row}`);
      const c = mattersSheet.getCell(`C${row}`);
      const d = mattersSheet.getCell(`D${row}`);
      const e = mattersSheet.getCell(`E${row}`);
      const f = mattersSheet.getCell(`F${row}`);
      const g = mattersSheet.getCell(`G${row}`);
      const h = mattersSheet.getCell(`H${row}`);
      const i = mattersSheet.getCell(`I${row}`);

      // const persisted =
      //   matter.display_number in persistedData.matters
      //     ? persistedData.matters[matter.display_number]
      //     : {
      //         Guaranteed: "",
      //         "On-Collection": "",
      //         Responsible: "",
      //         Originating: "",
      //       };
      // Updated code:
      // // Find this part in your matters function
      // const persisted =
      //   matter.display_number in persistedData.matters
      //     ? persistedData.matters[matter.display_number]
      //     : {
      //         Guaranteed: "",
      //         "On-Collection": "",
      //         Responsible: "",
      //         Originating: "",
      //       };

      // And replace it with this:
      let persisted = {
        OriginatingAtty: "",
        ResponsibleAtty: "",
        Guaranteed: "",
        "On-Collection": "",
        Responsible: "",
        Originating: "",
      };

      // Check if there's data for this matter ID
      if (matter.display_number in persistedData.matters) {
        // Get all records for this matter ID
        let matterData = persistedData.matters[matter.display_number];

        // If it's an array of records or object with attorney property
        if (Array.isArray(matterData)) {
          // Find the one that matches the current attorney
          let attorneyData = matterData.find(
            (item) => item.attorney === attorney
          );
          if (attorneyData) {
            persisted = attorneyData;
          }
        } else if (matterData.attorney === attorney || !matterData.attorney) {
          // If it's an object and matches the attorney or has no attorney (legacy data)
          persisted = matterData;
        }
      }
      //VALUES
      b.value = matter.display_number;
      console.log("__________persisted", persisted);
      // Replace with this updated code
      c.value = persisted.OriginatingAtty || "";
      d.value = persisted.ResponsibleAtty || "";
      f.value = persisted.Guaranteed === "" ? "" : +persisted.Guaranteed;
      g.value =
        persisted["On-Collection"] === "" ? "" : +persisted["On-Collection"];
      h.value = persisted.Responsible === "" ? "" : +persisted.Responsible;
      i.value = persisted.Originating === "" ? "" : +persisted.Originating;

      b.border = {
        left: { style: "double", color: { argb: "FF000000" } },
      };
      e.border = {
        left: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
      i.border = {
        right: { style: "double", color: { argb: "FF000000" } },
      };
      c.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      d.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      e.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D9D9D9" },
      };
      //Formats
      f.numFmt = "0%";
      g.numFmt = "0%";
      h.numFmt = "0%";
      i.numFmt = "0%";
      row++;
    });
  row = row - 1;

  mattersSheet.getCell(`B${row}`).border = {
    bottom: { style: "double", color: { argb: "FF000000" } },
    left: { style: "double", color: { argb: "FF000000" } },
  };
  mattersSheet.getCell(`C${row}`).border = {
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  mattersSheet.getCell(`D${row}`).border = {
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  mattersSheet.getCell(`E${row}`).border = {
    left: { style: "thin", color: { argb: "FF000000" } },
    right: { style: "thin", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  mattersSheet.getCell(`F${row}`).border = {
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  mattersSheet.getCell(`G${row}`).border = {
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  mattersSheet.getCell(`H${row}`).border = {
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  mattersSheet.getCell(`I${row}`).border = {
    bottom: { style: "double", color: { argb: "FF000000" } },
    right: { style: "double", color: { argb: "FF000000" } },
  };

  mattersSheet.mergeCells(`B${row + 1}`, `I${row + 1}`);
  const end = mattersSheet.getCell(`B${row + 1}`);
  end.value = "End";

  const e6 = mattersSheet.getCell("E6");
  const b7 = mattersSheet.getCell("B7");
  const c7 = mattersSheet.getCell("C7");
  const d7 = mattersSheet.getCell("D7");
  const e7 = mattersSheet.getCell("E7");
  const f7 = mattersSheet.getCell("F7");
  const g7 = mattersSheet.getCell("G7");
  const h7 = mattersSheet.getCell("H7");
  const i7 = mattersSheet.getCell("I7");

  //FONTS
  applyRange(mattersSheet, "A1:B1", "font", {
    name: "Calibri",
    size: 12,
    bold: "true",
  });
  b6.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };
  f6.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };

  b7.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };
  c7.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };
  d7.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };
  f7.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };
  g7.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };
  h7.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };
  i7.font = {
    name: "Calibri",
    size: 12,
    bold: "true",
  };
  //ALIGNENT

  b7.alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: true,
  };
  c7.alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  d7.alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  f7.alignment = {
    vertical: "bottom",
    horizontal: "left",
  };
  g7.alignment = {
    vertical: "bottom",
    horizontal: "center",
  };
  h7.alignment = {
    vertical: "bottom",
    horizontal: "center",
  };
  i7.alignment = {
    vertical: "bottom",
    horizontal: "center",
  };
  end.alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  //FILL
  e6.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  b7.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  c7.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  d7.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  e7.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  f7.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  g7.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  h7.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  i7.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  end.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  };
  //BORDERS
  b6.border = {
    left: { style: "double", color: { argb: "FF000000" } },
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  e6.border = {
    left: { style: "thin", color: { argb: "FF000000" } },
    right: { style: "thin", color: { argb: "FF000000" } },
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  f6.border = {
    right: { style: "double", color: { argb: "FF000000" } },
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };

  applyRange(mattersSheet, "B7:I7", "border", {
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  });
  e7.border = {
    left: { style: "thin", color: { argb: "FF000000" } },
    right: { style: "thin", color: { argb: "FF000000" } },
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  b7.border = {
    left: { style: "double", color: { argb: "FF000000" } },
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  i7.border = {
    right: { style: "double", color: { argb: "FF000000" } },
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  end.border = {
    bottom: { style: "double", color: { argb: "FF000000" } },
    top: { style: "double", color: { argb: "FF000000" } },
    left: { style: "double", color: { argb: "FF000000" } },
    right: { style: "double", color: { argb: "FF000000" } },
  };
};
